export const NMMobileFallback = [
  {
    metadata: {
      tags: [],
      concepts: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: '2kqx4czmzra1',
        },
      },
      id: 'rpP1jhX8FOqVoi5hDMX5D',
      type: 'Entry',
      createdAt: '2022-07-27T15:27:35.803Z',
      updatedAt: '2022-07-27T15:27:35.803Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      publishedVersion: 5,
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'frame',
        },
      },
      locale: 'en-US',
    },
    fields: {
      name: "Women's Mobile Homepage: Fallback",
      metaData: {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: '7cJqVhvKU7spWzaox3VdpW',
        },
        contentType: 'metaData',
        fields: {
          name: 'Homepage: Fallback',
          slugs: [
            '/womens-evergreen',
            '/editorial/fallback-test',
          ],
        },
      },
      brand: 'NM',
      channel: [
        'Mobile',
      ],
      l1Layouts: [
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: '7fKuRYULSGs96aBSEzZ6f3',
          },
          contentType: 'l1Layout',
          fields: {
            name: "Women's Mobile Homepage: Fallback",
            placement: 'Main',
            l2Layout: [
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '1NJABTSzICHTD4K3L2WZVm',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage: Fallback Banner 1',
                  desktopWidth: '90',
                  mobileWidth: '100',
                  isFullBleedLayout: true,
                  components: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '58PbnLmkdCdVPaJvYZ5oLW',
                      },
                      contentType: 'imageContentAsset',
                      fields: {
                        name: 'Homepage: Fallback Banner 1',
                        isLazyLoaded: false,
                        parallaxShiftZoomBehavior: false,
                        desktopImage: [
                          {
                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423742/content/marketing/r_HP_GenericBanner_112524.jpg',
                            tags: [],
                            type: 'upload',
                            bytes: 86395,
                            width: 2856,
                            format: 'jpg',
                            height: 184,
                            version: 1731423742,
                            duration: null,
                            metadata: {
                              brands: [
                                '0001',
                              ],
                              asset_type: '0001',
                              star_rating: '0001',
                              retouch_status: '0001',
                            },
                            public_id: 'content/marketing/r_HP_GenericBanner_112524',
                            created_at: '2024-11-12T15:02:22Z',
                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423742/content/marketing/r_HP_GenericBanner_112524.jpg',
                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731423742/content/marketing/r_HP_GenericBanner_112524.jpg',
                            resource_type: 'image',
                            raw_transformation: 'f_auto/q_auto:good',
                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731423742/content/marketing/r_HP_GenericBanner_112524.jpg',
                          },
                        ],
                        mobileImage: [
                          {
                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423735/content/marketing/m_HP_GenericBanner_112524.jpg',
                            tags: [],
                            type: 'upload',
                            bytes: 34659,
                            width: 717,
                            format: 'jpg',
                            height: 184,
                            version: 1731423735,
                            duration: null,
                            metadata: {
                              brands: [
                                '0001',
                              ],
                              asset_type: '0001',
                              star_rating: '0001',
                              retouch_status: '0001',
                            },
                            public_id: 'content/marketing/m_HP_GenericBanner_112524',
                            created_at: '2024-11-12T15:02:15Z',
                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423735/content/marketing/m_HP_GenericBanner_112524.jpg',
                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731423735/content/marketing/m_HP_GenericBanner_112524.jpg',
                            resource_type: 'image',
                            raw_transformation: 'f_auto/q_auto:good',
                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731423735/content/marketing/m_HP_GenericBanner_112524.jpg',
                          },
                        ],
                        linkTo: 'https://www.neimanmarcus.com/c/nm-offers--sales-cat66120739',
                      },
                    },
                  ],
                  verticalSpacingDesktop: '24',
                  verticalSpacingMobile: '0',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '1bsD7l9AaXOwD2CdgdoNv9',
                },
                contentType: 'l2Layout',
                fields: {
                  name: "Women's Mobile Homepage: Fallback Row 1",
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2EWKA1hy5ml5dg1HmgR3CW',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Fallback Mobile: Row 1',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1hUGINwvtp1hmyRtnCtAP9',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback Mobile: Row 1 Image',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3xDO83HGUwOwcGNu5Jvy4p',
                                  },
                                  contentType: 'videoContentAsset',
                                  fields: {
                                    name: 'Homepage: Fallback Row 1',
                                    videoUpload: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/video/upload/c_scale,f_auto,h_870,q_auto,w_1920/f_auto/q_auto:good/v1731360827/content/marketing/r_HP_GiftGuides_112524.mp4',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 97375953,
                                        width: 4838,
                                        format: 'mp4',
                                        height: 2192,
                                        version: 1731360827,
                                        duration: 127.0269,
                                        metadata: {
                                          brands: [
                                            '0001',
                                          ],
                                          asset_type: '0001',
                                          star_rating: '0001',
                                          retouch_status: '0001',
                                        },
                                        public_id: 'content/marketing/r_HP_GiftGuides_112524',
                                        created_at: '2024-11-11T21:33:47Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/video/upload/c_scale,f_auto,h_870,q_auto,w_1920/f_auto/q_auto:good/v1731360827/content/marketing/r_HP_GiftGuides_112524.mp4',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/video/upload/v1731360827/content/marketing/r_HP_GiftGuides_112524.mp4',
                                        resource_type: 'video',
                                        raw_transformation: 'c_scale,f_auto,h_870,q_auto,w_1920/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/video/upload/v1731360827/content/marketing/r_HP_GiftGuides_112524.mp4',
                                      },
                                    ],
                                    posterUpload: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_870,w_1920/f_auto/q_auto:good/v1731361073/content/marketing/r_HP_GiftGuides_Poster_112524.jpg',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 1199471,
                                        width: 2856,
                                        format: 'jpg',
                                        height: 1294,
                                        version: 1731361073,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            '0001',
                                          ],
                                          asset_type: '0001',
                                          star_rating: '0001',
                                          retouch_status: '0001',
                                        },
                                        public_id: 'content/marketing/r_HP_GiftGuides_Poster_112524',
                                        created_at: '2024-11-11T21:37:53Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_870,w_1920/f_auto/q_auto:good/v1731361073/content/marketing/r_HP_GiftGuides_Poster_112524.jpg',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731361073/content/marketing/r_HP_GiftGuides_Poster_112524.jpg',
                                        resource_type: 'image',
                                        raw_transformation: 'c_scale,h_870,w_1920/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731361073/content/marketing/r_HP_GiftGuides_Poster_112524.jpg',
                                      },
                                    ],
                                    mobileVideoUpload: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/video/upload/c_scale,dpr_2.0,f_auto,h_938,q_auto,w_750/f_auto/q_auto:good/v1731423758/content/marketing/m_HP_GiftGuides_112524.mp4',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 92270126,
                                        width: 4240,
                                        format: 'mp4',
                                        height: 5300,
                                        version: 1731423758,
                                        duration: 116.883437,
                                        metadata: {
                                          asset_type: '0001',
                                          brands: [
                                            '0001',
                                          ],
                                          retouch_status: '0001',
                                          star_rating: '0001',
                                        },
                                        public_id: 'content/marketing/m_HP_GiftGuides_112524',
                                        created_at: '2024-11-12T15:02:38Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/video/upload/c_scale,dpr_2.0,f_auto,h_938,q_auto,w_750/f_auto/q_auto:good/v1731423758/content/marketing/m_HP_GiftGuides_112524.mp4',
                                        resource_type: 'video',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/video/upload/v1731423758/content/marketing/m_HP_GiftGuides_112524.mp4',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/video/upload/v1731423758/content/marketing/m_HP_GiftGuides_112524.mp4',
                                        raw_transformation: 'c_scale,dpr_2.0,f_auto,h_938,q_auto,w_750/f_auto/q_auto:good',
                                      },
                                    ],
                                    mobilePosterUpload: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423740/content/marketing/m_HP_GiftGuidesPoster_112524.jpg',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 535556,
                                        width: 750,
                                        format: 'jpg',
                                        height: 938,
                                        version: 1731423740,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            '0001',
                                          ],
                                          asset_type: '0001',
                                          star_rating: '0001',
                                          retouch_status: '0001',
                                        },
                                        public_id: 'content/marketing/m_HP_GiftGuidesPoster_112524',
                                        created_at: '2024-11-12T15:02:20Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731423740/content/marketing/m_HP_GiftGuidesPoster_112524.jpg',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731423740/content/marketing/m_HP_GiftGuidesPoster_112524.jpg',
                                        resource_type: 'image',
                                        raw_transformation: 'f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731423740/content/marketing/m_HP_GiftGuidesPoster_112524.jpg',
                                      },
                                    ],
                                    autoPlay: true,
                                    loop: true,
                                    mute: true,
                                    hideControls: true,
                                    lazyloadVideo: false,
                                  },
                                },
                              ],
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2C2XJf0F2dxDTfsIGWDo4E',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Fallback Row 1 Text',
                              mobileWidth: '90',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4v3IsUs9Cc8vrQmaTc420v',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage: Fallback Row 1 Text',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: 'HOLIDAY GIFT GUIDE',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: 'On Your Wish List',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-1',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: 'Discover the perfect gifts at Neiman Marcus–where luxury meets quality & treasures await. Shop our curated collections of top-rated items that make every moment memorable.',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/gifts-all-gifts-cat80110753',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'SHOP ALL GIFTS',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    textJustification: 'Center',
                                    textColor: 'white',
                                    buttonStyle: 'button-transparent-white',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingMobile: '0',
                        groupId: 'Group 1',
                        p13nTag: {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '157ifdGmpiSu5EsZOQz2N3',
                          },
                          contentType: 'p13n',
                          fields: {
                            name: "Women's Homepage Personalization 1",
                            contentTag: [
                              'p13n_womens_robertocoin_jewelry_111124',
                            ],
                          },
                        },
                        backgroundColor: '#9E1113',
                      },
                    },
                  ],
                  verticalSpacingMobile: '16',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '6fZbnJ6KXB45q04VkPCyTT',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage Fallback: Mobile Nav (Gift Guides)',
                  mobileWidth: '96',
                  isFullBleedLayout: true,
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '7z1aU2KQkPJj8XVag4sDSW',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's: Desktop Nav Lead-In (Gift Guides)",
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '66Ah0tDen7d1r83JvLopOp',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: "Homepage Women's: Desktop Nav Lead-In (Gift Guides)",
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Gift Guides',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-4',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-2',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Gift Guides',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              oneAppIsShowPlpDisclaimerPopup: false,
                            },
                          },
                        ],
                        verticalSpacingMobile: '0',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'Jp8uMPZLfPL91E66EJMbQ',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Fallback: Mobile Nav (Gift Guides)',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3PGWMbEHS8lmd3etEbnxwk',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 1',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1H7oqQI8vywrJfRvvbYWkm',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Gift Guides) 1',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2vucEXN0MBSSQOrGWTdz47',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 1',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6700,w_5958,y_4901/c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726523969/content/creative/CHOPARD_NODEPIC_072524_025.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 184808088,
                                            width: 5958,
                                            format: 'tiff',
                                            height: 7614,
                                            context: {
                                              custom: {
                                                alt: "Mini Quatre Pendant|\nRg Diva's Dream Mop Pendant|\nRg Happy Heart Mop Pendant|\nMove Uno Pave Necklace|\nPom Pom Dot Malachite Necklace",
                                                Vendors: 'Boucheron| Bvlgari| Chopard| Messika| Pomellato',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                'PIM Style': '4858980| 4763575| 4771210| 4811447',
                                                Depictions: 'PLK0T| PKBH4| PKJ9K| PL9U5',
                                                'Art Director': 'Jonas Park',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                              },
                                            },
                                            version: 1726523969,
                                            duration: null,
                                            metadata: {
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              categories: [
                                                'vpqoyqkbgk8ejrorvep6',
                                              ],
                                              shoot_date: '2024-07-25',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              campaign_types: [
                                                'lwlvaocntzgohrkdjuuf',
                                              ],
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              expiration_date: '2025-07-25',
                                            },
                                            public_id: 'content/creative/CHOPARD_NODEPIC_072524_025',
                                            created_at: '2024-08-06T14:03:47Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6700,w_5958,y_4901/c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726523969/content/creative/CHOPARD_NODEPIC_072524_025.tiff',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726523969/content/creative/CHOPARD_NODEPIC_072524_025.tiff',
                                            resource_type: 'image',
                                            raw_transformation: 'c_crop,g_xy_center,h_6700,w_5958,y_4901/c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726523969/content/creative/CHOPARD_NODEPIC_072524_025.tiff',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-gifts-for-her-cat000686',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5pzlSgzfxJM3DF2IT9z0sq',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 1',
                                        desktopText: {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [
                                                    {
                                                      type: 'italic',
                                                    },
                                                  ],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                                {
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-gifts-for-her-cat000686',
                                                  },
                                                  content: [
                                                    {
                                                      data: {},
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      value: 'GIFTS FOR HER',
                                                      nodeType: 'text',
                                                    },
                                                  ],
                                                  nodeType: 'hyperlink',
                                                },
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'heading-5',
                                            },
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'paragraph',
                                            },
                                          ],
                                          nodeType: 'document',
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '343Zv7xSp5b6JTKORQ3v11',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 2',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6VbLX3FfI1wHa5z8QIIzkI',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Gift Guides) 2',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4fEaM9HpPBWAjb2w0Zxdsp',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 2',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346939/content/marketing/r_HP_GiftsforHome_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 717955,
                                            width: 552,
                                            format: 'jpg',
                                            height: 939,
                                            version: 1731346939,
                                            duration: null,
                                            metadata: {
                                              brands: [
                                                '0001',
                                              ],
                                              asset_type: '0001',
                                              star_rating: '0001',
                                              retouch_status: '0001',
                                            },
                                            public_id: 'content/marketing/r_HP_GiftsforHome_112524',
                                            created_at: '2024-11-11T17:42:19Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346939/content/marketing/r_HP_GiftsforHome_112524.jpg',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731346939/content/marketing/r_HP_GiftsforHome_112524.jpg',
                                            resource_type: 'image',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731346939/content/marketing/r_HP_GiftsforHome_112524.jpg',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-gifts-for-him-cat21230736',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '284pZck8awu6DeGtlFwSAu',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 2',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-gifts-for-him-cat21230736',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'GIFTS FOR HIM',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1vyO4kMHFFFVfaJxTh51dP',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 3',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2hYihamPNE9icSUTjazRdu',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Gift Guides) 3',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2KHujmqRl3kIewkuHz9g6C',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 3',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346944/content/marketing/r_HP_OurFavoriteGifts_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 378264,
                                            width: 552,
                                            format: 'jpg',
                                            height: 939,
                                            version: 1731346944,
                                            duration: null,
                                            metadata: {
                                              brands: [
                                                '0001',
                                              ],
                                              asset_type: '0001',
                                              star_rating: '0001',
                                              retouch_status: '0001',
                                            },
                                            public_id: 'content/marketing/r_HP_OurFavoriteGifts_112524',
                                            created_at: '2024-11-11T17:42:24Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346944/content/marketing/r_HP_OurFavoriteGifts_112524.jpg',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731346944/content/marketing/r_HP_OurFavoriteGifts_112524.jpg',
                                            resource_type: 'image',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731346944/content/marketing/r_HP_OurFavoriteGifts_112524.jpg',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-featured-gift-shops-our-favorite-gifts-cat80410737',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4l2GBmkYM6rxL9fOvhE8Bh',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 3',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-featured-gift-shops-our-favorite-gifts-cat80410737',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'OUR FAVORITE GIFTS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1UvXAOLvvAncTTsWDXlWTo',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 4',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2FzWSgsUhoXCYGk6akcuxu',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Gift Guides) 4',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4vfmyv3SaaLzoRdvcHetSx',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 4',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346941/content/marketing/r_HP_GourmetFood_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 414450,
                                            width: 552,
                                            format: 'jpg',
                                            height: 939,
                                            version: 1731346941,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                              retouch_status: '0001',
                                              star_rating: '0001',
                                            },
                                            public_id: 'content/marketing/r_HP_GourmetFood_112524',
                                            created_at: '2024-11-11T17:42:21Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346941/content/marketing/r_HP_GourmetFood_112524.jpg',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731346941/content/marketing/r_HP_GourmetFood_112524.jpg',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731346941/content/marketing/r_HP_GourmetFood_112524.jpg',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-featured-gift-shops-our-favorite-gifts-cat80410737',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4Kj2l67BCOiKoarAYUq9xl',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 4',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-featured-gift-shops-our-favorite-gifts-cat80410737',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'GOURMET FOOD & TREATS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '49rMjXh6lMOFjDXUffppkS',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 5',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1VuyBwWwIZx9NnKkbHKwr5',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Gift Guides) 5',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: 'MsaA5ATzaPeOJgkoNmXGH',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 5',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346937/content/marketing/r_HP_AllGifts_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 420329,
                                            width: 552,
                                            format: 'jpg',
                                            height: 939,
                                            version: 1731346937,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                              retouch_status: '0001',
                                              star_rating: '0001',
                                            },
                                            public_id: 'content/marketing/r_HP_AllGifts_112524',
                                            created_at: '2024-11-11T17:42:17Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731346937/content/marketing/r_HP_AllGifts_112524.jpg',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731346937/content/marketing/r_HP_AllGifts_112524.jpg',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731346937/content/marketing/r_HP_AllGifts_112524.jpg',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-all-gifts-cat80110753',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '7u6OgsadvL2pZyhHGG1wvQ',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Gift Guides) 5',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-all-gifts-cat80110753',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'ALL GIFTS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'se8JHw8YBXVgbLK9KOo7H',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 6',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2WCk549eIzyYikc3dF3urN',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage Fallback: Mobile Nav (Gift Guides) 6',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4djU8h9BD4vhoSuGjFZSG3',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 6',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524553/content/creative/GUERLAIN_NODEPIC_072524_041.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 155038700,
                                            width: 5691,
                                            format: 'tiff',
                                            height: 6687,
                                            version: 1726524553,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-25',
                                              shoot_date: '2024-07-25',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'lwlvaocntzgohrkdjuuf',
                                              ],
                                              categories: [
                                                'jiajmknabaz4krln8t26',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Dior Liquid Sequins|\nHoliday Palette|\nGuerlain x Shourouk Ombres G|\nAmbient Lighting Edit Palette|\nMini Lip Set',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                Depictions: 'C669E| C66LU| C66XM',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                'PIM Style': '4849314| 4900695| 4864785| 4870288',
                                                Vendors: 'Dior| Gee Beauty| Guerlain| Hourglass Cosmetics| TOM FORD',
                                              },
                                            },
                                            public_id: 'content/creative/GUERLAIN_NODEPIC_072524_041',
                                            created_at: '2024-08-06T14:09:10Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524553/content/creative/GUERLAIN_NODEPIC_072524_041.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726524553/content/creative/GUERLAIN_NODEPIC_072524_041.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726524553/content/creative/GUERLAIN_NODEPIC_072524_041.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/beauty-gifts-sets-cat58990768',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '41FlXP5nPy9UwwCl0Zhn7s',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 6',
                                        desktopText: {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                                {
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/beauty-gifts-sets-cat58990768',
                                                  },
                                                  content: [
                                                    {
                                                      data: {},
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      value: 'BEAUTY GIFTS & SETS',
                                                      nodeType: 'text',
                                                    },
                                                  ],
                                                  nodeType: 'hyperlink',
                                                },
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'heading-5',
                                            },
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'paragraph',
                                            },
                                          ],
                                          nodeType: 'document',
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 99,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '31xFAHJWAKvosWPHtujyg5',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 7',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1JmoFhyl5uQ5VbTsMfbTNN',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage Fallback: Mobile Nav (Gift Guides) 7',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1NbPzQ17FmjcU1wgKPn6Yf',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 7',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731362418/content/marketing/m_HP_GiftsUnder100_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 162346,
                                            width: 400,
                                            format: 'jpg',
                                            height: 681,
                                            version: 1731362418,
                                            duration: null,
                                            metadata: {
                                              brands: [
                                                '0001',
                                              ],
                                              asset_type: '0001',
                                              star_rating: '0001',
                                              retouch_status: '0001',
                                            },
                                            public_id: 'content/marketing/m_HP_GiftsUnder100_112524',
                                            created_at: '2024-11-11T22:00:18Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1731362418/content/marketing/m_HP_GiftsUnder100_112524.jpg',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731362418/content/marketing/m_HP_GiftsUnder100_112524.jpg',
                                            resource_type: 'image',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731362418/content/marketing/m_HP_GiftsUnder100_112524.jpg',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-gifts-under-100-cat24520734',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: 'VGr1gBtkzKYRNbQWOm9YB',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 7',
                                        desktopText: {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [
                                                    {
                                                      type: 'italic',
                                                    },
                                                  ],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                                {
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-gifts-under-100-cat24520734',
                                                  },
                                                  content: [
                                                    {
                                                      data: {},
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      value: 'GIFTS UNDER $100',
                                                      nodeType: 'text',
                                                    },
                                                  ],
                                                  nodeType: 'hyperlink',
                                                },
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'heading-5',
                                            },
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'paragraph',
                                            },
                                          ],
                                          nodeType: 'document',
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 99,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3nZ2gpH9O2QEg7xllwWLsi',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 8',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6bApSzgcsuHoSVFt1dPkzm',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage Fallback: Mobile Nav (Gift Guides) 8',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: 'aiJDAvXk3m1ptdB1qx1IM',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 8',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_5800,w_5352,y_4192/c_fill,dpr_2.0,g_xy_center,h_468,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726523329/content/creative/AUTEUR_NODEPIC_072424_070.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 137406620,
                                            width: 5352,
                                            format: 'tiff',
                                            height: 6288,
                                            version: 1726523329,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-24',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-07-24',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'jiajmknabaz4krln8t26',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Definitive Exploration Set – Renewal|\nBr540 Sparkling Body Oil|\nCracker Set Of 6 Mini Crackers|\nTheragun Mini - Nm Exclusive|\nVictoria Beckham Beauty - Hand Cream Set',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                Depictions: 'C62NM| C6783',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                'PIM Style': '4667017| 4885264',
                                                Vendors: 'Auteur| Maison Francis Kurkdjian| Slip| Therabody| Victoria Beckham Beauty',
                                              },
                                            },
                                            public_id: 'content/creative/AUTEUR_NODEPIC_072424_070',
                                            created_at: '2024-08-06T14:05:50Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_5800,w_5352,y_4192/c_fill,dpr_2.0,g_xy_center,h_468,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726523329/content/creative/AUTEUR_NODEPIC_072424_070.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726523329/content/creative/AUTEUR_NODEPIC_072424_070.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726523329/content/creative/AUTEUR_NODEPIC_072424_070.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_5800,w_5352,y_4192/c_fill,dpr_2.0,g_xy_center,h_468,w_276,x_2800/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-stocking-stuffers-cat1728525055',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1bLPyVUJflGMwgE0ca5ftL',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 8',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-stocking-stuffers-cat1728525055',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'STOCKING STUFFERS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'kjyPKGUi6fSoXWQVjZd8x',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 9',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3EhIJhXwhAwwoBaEna5Vpn',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage Fallback: Mobile Nav (Gift Guides) 9',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3MRddsumewj6Q599jbzPhl',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 9',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_3300/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524227/content/creative/DOLCE_NODEPIC_072424_038.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 187373096,
                                            width: 6267,
                                            format: 'tiff',
                                            height: 7363,
                                            version: 1726524227,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-24',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-07-24',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'bwipmsmf95wk7stu76dc',
                                                'o0cnjhojynetycucak2u',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Moka Pot Set|Large Moka Pot',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                Depictions: 'HGP60| HGP5Z',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                'PIM Style': '4858987| 4858986',
                                                Vendors: 'Dolce & Gabbana',
                                              },
                                            },
                                            public_id: 'content/creative/DOLCE_NODEPIC_072424_038',
                                            created_at: '2024-08-06T14:08:05Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_3300/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524227/content/creative/DOLCE_NODEPIC_072424_038.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726524227/content/creative/DOLCE_NODEPIC_072424_038.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726524227/content/creative/DOLCE_NODEPIC_072424_038.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,g_xy_center,h_469,w_276,x_3300/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-gifts-by-category-home-gifts-cat80340803',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5GD6052C8FN64yQAoyIMRT',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 9',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-gifts-by-category-home-gifts-cat80340803',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'HOME GIFTS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5QZ7vYjvcSjI0D8HglIh8U',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Fallback: Mobile Nav (Gift Guides) 10',
                              mobileWidth: '50',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5CISW1inTrI5nRExK9aF9w',
                                  },
                                  contentType: 'textOverImageContentAsset',
                                  fields: {
                                    name: 'Homepage Fallback: Mobile Nav (Gift Guides) 10',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '6bSCMniHA2w6DSx8OaKH52',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 10',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524440/content/creative/HARPER_NODEPIC_081924_054_COMP.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                              'NY-HAPPILY EVER 24',
                                            ],
                                            type: 'upload',
                                            bytes: 120817888,
                                            width: 4470,
                                            format: 'tiff',
                                            height: 6600,
                                            version: 1726524440,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2024-08-19',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-08-19',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'ks9lhmtimjwmhpn37znc',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Luggage',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                Vendors: 'Harper Collective',
                                              },
                                            },
                                            public_id: 'content/creative/HARPER_NODEPIC_081924_054_COMP',
                                            created_at: '2024-07-26T14:45:35Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good/v1726524440/content/creative/HARPER_NODEPIC_081924_054_COMP.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726524440/content/creative/HARPER_NODEPIC_081924_054_COMP.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726524440/content/creative/HARPER_NODEPIC_081924_054_COMP.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,h_469,w_276/b_rgb:000000,c_fill,e_gradient_fade:20,g_xy_center,h_1.0,w_1.0,y_-0.3/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/gifts-extraordinary-gifts-cat1728525701',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '40tq7BMncIkYSiB6GU4gl0',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage Fallback: Mobile Nav (Gift Guides) 10',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'heading-5',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/gifts-extraordinary-gifts-cat1728525701',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'EXTRAORDINARY GIFTS',
                                                      marks: [
                                                        {
                                                          type: 'italic',
                                                        },
                                                      ],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        textColor: 'white',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom Center',
                                    desktopTextWidth: 90,
                                    desktopTextMarginVertical: 2,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        topSpacingMobile: '16',
                        parallaxEffect: 'Dynamic Horizontal Content Rail',
                      },
                    },
                  ],
                  verticalSpacingMobile: '8',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '2FLwNzbvRPaLGrtdjMGFxr',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage Fallback: Mobile Nav (Where to Wear)',
                  mobileWidth: '96',
                  isFullBleedLayout: true,
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '26vW1keOGGYuLY926dAV5V',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's: Desktop Nav Lead-In",
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'om0QVChOGwCZ0sekeo4bw',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: "Homepage Women's: Desktop Nav Lead-In",
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Where to Wear',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-4',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-2',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Where to Wear',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                            },
                          },
                        ],
                        verticalSpacingMobile: '16',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '62JTltpDSGrSvCRGGjDW53',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Fallback: Mobile Nav (Where to Wear)',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '46j7OjYZzlivNHh0Qcbqv7',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 1',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2P4uGocJ9uRdA9DiV8ifU5',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Where to Wear) 1',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3DJSSDW7Cd3InO1fCVtq3H',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 1',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_9000,w_8736,y_5400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726093244/content/creative/OLIVIA_NODEPIC_071524_0093.tiff',
                                            tags: [
                                              'TREAT YOURSELF ON FIG 24',
                                            ],
                                            type: 'upload',
                                            bytes: 305297472,
                                            width: 8736,
                                            format: 'tiff',
                                            height: 11648,
                                            version: 1726093244,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-15',
                                              launch_date: '2024-11-10',
                                              shoot_date: '2024-07-15',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Sibella Sequin Strapless Draped Bow Back Mini Dress',
                                                'Art Director': 'Christopher Miller',
                                                caption: 'HOLIDAY 25| TREAT YOURSELF ON FIG',
                                                'Digital Tech': 'Andrea Fremiotti',
                                                Photographer: 'Marc Hibbert',
                                                Vendors: 'Alice + Olivia',
                                              },
                                            },
                                            public_id: 'content/creative/OLIVIA_NODEPIC_071524_0093',
                                            created_at: '2024-07-22T18:34:17Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_9000,w_8736,y_5400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726093244/content/creative/OLIVIA_NODEPIC_071524_0093.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726093244/content/creative/OLIVIA_NODEPIC_071524_0093.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726093244/content/creative/OLIVIA_NODEPIC_071524_0093.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_9000,w_8736,y_5400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '7qCn2XuoIH0ZdcixODYXw2',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 1',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'NIGHT OUT',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'omFqNpKKCu21l1OEnWC3W',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 2',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2gsui3lx6aWR56KXN8ArPe',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Where to Wear) 2',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3ZFV5ORQb60i5FgcYkKGIK',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 2',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_5400,w_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2000/f_auto/q_auto:good/v1726612947/content/creative/TSE_NODEPIC_081824_036.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                              'NY-HAPPILY EVER 24',
                                            ],
                                            type: 'upload',
                                            bytes: 74788468,
                                            width: 4400,
                                            format: 'tiff',
                                            height: 6600,
                                            version: 1726612947,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-19',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-07-19',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Sequins Ls Top| Silk Pant| Silk Tank',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                Depictions: 'T50E0| T50DU',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                'PIM Style': '4609153| 4609147',
                                                Vendors: 'TSE',
                                              },
                                            },
                                            public_id: 'content/creative/TSE_NODEPIC_081824_036',
                                            created_at: '2024-07-23T19:04:28Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_5400,w_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2000/f_auto/q_auto:good/v1726612947/content/creative/TSE_NODEPIC_081824_036.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726612947/content/creative/TSE_NODEPIC_081824_036.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726612947/content/creative/TSE_NODEPIC_081824_036.tiff',
                                            raw_transformation: 'c_crop,h_5400,w_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2000/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/wear-to-garden-party-cat1720310713',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3VCVTFhOhG9m7K0MpHdtM4',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 2',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/wear-to-garden-party-cat1720310713',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'HOLIDAY CASUAL',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7JleWAsVzOXH5zCe6FWChx',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 3',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2xqfxALj2K6pQraWx1Eq4h',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Where to Wear) 3',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1KEXkNNTd6YFSyvUp4r39I',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 3',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1725487986/content/creative/ROSE_NODEPIC_072324_038.tiff',
                                            tags: [
                                              'YOU’RE INVITED 24',
                                            ],
                                            type: 'upload',
                                            bytes: 234456428,
                                            width: 8708,
                                            format: 'tiff',
                                            height: 11608,
                                            version: 1725487986,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-23',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-07-23',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                              channels: [
                                                'c6gkcb2srckettgdzfc8',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Exclusive Black Boustier With Illusion Detail',
                                                'Art Director': 'Pamela Libonati',
                                                caption: 'HOLIDAY 25| YOU’RE INVITED',
                                                Depictions: 'T66W1',
                                                'Digital Tech': 'Eric Sonson',
                                                Photographer: 'Giseok Cho',
                                                'PIM Style': '4918134',
                                                Vendors: 'CHIARA BONI FOR LA PETITE ROBE',
                                              },
                                            },
                                            public_id: 'content/creative/ROSE_NODEPIC_072324_038',
                                            created_at: '2024-08-22T18:40:23Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1725487986/content/creative/ROSE_NODEPIC_072324_038.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1725487986/content/creative/ROSE_NODEPIC_072324_038.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1725487986/content/creative/ROSE_NODEPIC_072324_038.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-wedding-festivities-cat85900764',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1WdtbIpS51Tt564avcAiC9',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 3',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-wedding-festivities-cat85900764',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'WEDDING',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4Y5JCCGNh1tw1fP8RiLxz4',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 4',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3OrCuGLBCfxOaoqfHat00I',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Where to Wear) 4',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1e14Nq1EXAK2OezqDHgel9',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 4',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6800,w_11648,y_3700/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1727390950/content/creative/MONCLER_2_NODEPIC_071824_120.tiff',
                                            tags: [
                                              'COLD WEATHER 24',
                                            ],
                                            type: 'upload',
                                            bytes: 147416140,
                                            width: 11648,
                                            format: 'tiff',
                                            height: 8736,
                                            version: 1727390950,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-18',
                                              launch_date: '2024-10-21',
                                              shoot_date: '2024-07-18',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'vcovhktak3itubr6zjpb',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Bonrepos Long Coat Charcoal| Gigi Pocket Ankle Boots| Mini Puf Cross Body Bag| Soft Mohair Diamond Patterned Crewneck Sweater| Wool High Rise Trousers Charcoal',
                                                'Art Director': 'Christopher Miller',
                                                caption: 'HOLIDAY 25| COLD WEATHER',
                                                Depictions: 'T5UTP| T5UU8| T5UTY',
                                                'Digital Tech': 'Tadaaki Shibuya',
                                                Photographer: 'Christian MacDonald',
                                                'PIM Style': '4830788| 4841907| 4830518| 4830809| 4830798',
                                                Vendors: 'Moncler',
                                              },
                                            },
                                            public_id: 'content/creative/MONCLER_2_NODEPIC_071824_120',
                                            created_at: '2024-07-30T20:20:45Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6800,w_11648,y_3700/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1727390950/content/creative/MONCLER_2_NODEPIC_071824_120.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727390950/content/creative/MONCLER_2_NODEPIC_071824_120.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727390950/content/creative/MONCLER_2_NODEPIC_071824_120.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_6800,w_11648,y_3700/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/vacation-winter-getaway-cat1728575002',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3JfLCYJSMFaqAz2WW0D2Y1',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 4',
                                        desktopText: {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                                {
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/vacation-winter-getaway-cat1728575002',
                                                  },
                                                  content: [
                                                    {
                                                      data: {},
                                                      marks: [],
                                                      value: 'WINTER GETAWAY',
                                                      nodeType: 'text',
                                                    },
                                                  ],
                                                  nodeType: 'hyperlink',
                                                },
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'paragraph',
                                            },
                                          ],
                                          nodeType: 'document',
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1sXbT3Z0ch158NmEf12UXo',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 5',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5kw778BUxstImjtSB6Squ0',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (Where to Wear) 5',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '47eseoaTee98yQUrz8zqnF',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 5',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_2600,w_4200/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2700/f_auto/q_auto:good/v1726523759/content/creative/ENDPAPER_NODEPIC_072224_084.tiff',
                                            tags: [
                                              'YOU’RE INVITED 24',
                                            ],
                                            type: 'upload',
                                            bytes: 65075560,
                                            width: 4200,
                                            format: 'tiff',
                                            height: 3707,
                                            version: 1726523759,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-22',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-07-22',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Schiaparelli',
                                                'Art Director': 'Pamela Libonati',
                                                caption: 'HOLIDAY 25| YOU’RE INVITED',
                                                'Digital Tech': 'Eric Sonson',
                                                Photographer: 'Giseok Cho',
                                                Vendors: 'Schiaparelli',
                                              },
                                            },
                                            public_id: 'content/creative/ENDPAPER_NODEPIC_072224_084',
                                            created_at: '2024-08-22T18:53:50Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_2600,w_4200/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2700/f_auto/q_auto:good/v1726523759/content/creative/ENDPAPER_NODEPIC_072224_084.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726523759/content/creative/ENDPAPER_NODEPIC_072224_084.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726523759/content/creative/ENDPAPER_NODEPIC_072224_084.tiff',
                                            raw_transformation: 'c_crop,g_south,h_2600,w_4200/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2700/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-wedding-festivities-wedding-guest-black-tie-cat83900735',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2XF2kKaL4IDoiMv1PjfGeA',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (Where to Wear) 5',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-wedding-festivities-wedding-guest-black-tie-cat83900735',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'BLACK TIE',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2yQQFDSCp8AdAPzTSAIF9x',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 6',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: 'ogcmbATYDJl1O1Uqg0Ilm',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Mobile Nav (Where to Wear) 6',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5yc3ObO5kJ8L9sX9Y6gYcb',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 6',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6200,w_5350,y_3300/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3500/f_auto/q_auto:good/v1727217273/content/creative/ZIMMERMANN_NODEPIC_073124_237.tiff',
                                            tags: [
                                              'VOLUME ON FIG PT. 1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 128762768,
                                            width: 5350,
                                            format: 'tiff',
                                            height: 8021,
                                            version: 1727217273,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-30',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-07-30',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'hbde6kjohubvv01t6rmk',
                                                '0001',
                                              ],
                                              channels: [
                                                'c6gkcb2srckettgdzfc8',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Crush Embellished Tee| Crush Jacquard Barrel Coat| Crush Straight Jean',
                                                'Art Director': 'Peggy Bennett',
                                                caption: 'HOLIDAY 25| VOLUME ON FIG PT. 1',
                                                'Digital Tech': 'Joshua Martin',
                                                Photographer: 'Nick Prendergast',
                                                Vendors: 'Zimmermann',
                                              },
                                            },
                                            public_id: 'content/creative/ZIMMERMANN_NODEPIC_073124_237',
                                            created_at: '2024-08-06T20:20:43Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6200,w_5350,y_3300/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3500/f_auto/q_auto:good/v1727217273/content/creative/ZIMMERMANN_NODEPIC_073124_237.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727217273/content/creative/ZIMMERMANN_NODEPIC_073124_237.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727217273/content/creative/ZIMMERMANN_NODEPIC_073124_237.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_6200,w_5350,y_3300/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3500/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-for-the-weekend-cat91030742',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '17nUnYUc6g87bWlSd81iz9',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 6',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-for-the-weekend-cat91030742',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'WEEKEND',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6lhqVKp9iWUHDR7iWsBcVj',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 7',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: 'yojFxHAfHldRYNftdVrAw',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Mobile Nav (Where to Wear) 7',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2f8wAADesshPshhoGUs3bP',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 7',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4300,w_4400,y_2400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726524382/content/creative/LOEWE_NODEPIC_081924_012_COMP.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                              'NY-HAPPILY EVER 24',
                                            ],
                                            type: 'upload',
                                            bytes: 81143116,
                                            width: 4400,
                                            format: 'tiff',
                                            height: 6600,
                                            version: 1726524382,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-08-19',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-08-19',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'r2byaqh6ppzzhofx3apm',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Flamenco Purse Metallic In Laminated Lambskin',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Jonas Park',
                                                Vendors: 'Loewe',
                                              },
                                            },
                                            public_id: 'content/creative/LOEWE_NODEPIC_081924_012_COMP',
                                            created_at: '2024-07-26T15:06:14Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4300,w_4400,y_2400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726524382/content/creative/LOEWE_NODEPIC_081924_012_COMP.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726524382/content/creative/LOEWE_NODEPIC_081924_012_COMP.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726524382/content/creative/LOEWE_NODEPIC_081924_012_COMP.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_4300,w_4400,y_2400/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shop-the-edits-season-standouts-cat1722263728',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: 'eB2UTB1OJlEXn1oF8gFOd',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 7',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shop-the-edits-season-standouts-cat1722263728',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'SEASON STANDOUTS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3aR3hOLPwZpbGz8InrY2Yy',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 8',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '60ussEKvmXstFKgH8hM97r',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Mobile Nav (Where to Wear) 8',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4RszI46pvGTUaj7fjt7Dbm',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 8',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726524969/content/creative/ROW_NODEPIC_071624_0119.tiff',
                                            tags: [
                                              'TREAT YOURSELF ON FIG 24',
                                            ],
                                            type: 'upload',
                                            bytes: 183804540,
                                            width: 6552,
                                            format: 'tiff',
                                            height: 8736,
                                            version: 1726524969,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-16',
                                              launch_date: '2024-11-10',
                                              shoot_date: '2024-07-16',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Jan Coat',
                                                'Art Director': 'Christopher Miller',
                                                caption: 'HOLIDAY 25| TREAT YOURSELF ON FIG',
                                                'Digital Tech': 'Andrea Fremiotti',
                                                Photographer: 'Marc Hibbert',
                                                Vendors: 'The Row',
                                              },
                                            },
                                            public_id: 'content/creative/ROW_NODEPIC_071624_0119',
                                            created_at: '2024-07-22T20:16:37Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726524969/content/creative/ROW_NODEPIC_071624_0119.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726524969/content/creative/ROW_NODEPIC_071624_0119.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726524969/content/creative/ROW_NODEPIC_071624_0119.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shop-the-edits-designer-collections-cat1721788172',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4IeAYcIsJGizHCMbYLNoN6',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 8',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shop-the-edits-designer-collections-cat1721788172',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'DESIGNER',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4Au7EsLeiEbl5iPqYG44P9',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 9',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '23qVkwtXegq9z1LAw0L4wk',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Mobile Nav (Where to Wear) 9',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2y8cLejG1mLpyokiE7T0Q9',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 9',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6300,w_6336,y_4800/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1722953236/content/creative/GOLDEN_GOOSE_NODEPIC_072424_080.tiff',
                                            tags: [
                                              'DAL- HAPPILY EVER STILLS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 40697935,
                                            width: 6336,
                                            format: 'tiff',
                                            height: 8448,
                                            version: 1722953236,
                                            duration: null,
                                            metadata: {
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'rho185vmgblznspgvdsa',
                                              ],
                                              composition: 'jkvyc553vnyzofslenmd',
                                              expiration_date: '2025-07-24',
                                              launch_date: '2024-12-01',
                                              retouch_status: 'vjydqvl42qtaarpaxuxf',
                                              shoot_date: '2024-07-24',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                            },
                                            context: {
                                              custom: {
                                                'Art Director': 'Jonas Park',
                                                Depictions: 'X6QNH',
                                                'Digital Tech': 'David Nix',
                                                'PIM Style': '4888513',
                                                Photographer: 'Jonas Park',
                                                Vendors: 'Golden Goose',
                                                alt: 'NMX Ballstar',
                                                caption: 'HOLIDAY 25| DAL and NY HAPPILY EVER',
                                              },
                                            },
                                            public_id: 'content/creative/GOLDEN_GOOSE_NODEPIC_072424_080',
                                            created_at: '2024-08-06T14:07:16Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6300,w_6336,y_4800/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1722953236/content/creative/GOLDEN_GOOSE_NODEPIC_072424_080.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1722953236/content/creative/GOLDEN_GOOSE_NODEPIC_072424_080.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1722953236/content/creative/GOLDEN_GOOSE_NODEPIC_072424_080.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_6300,w_6336,y_4800/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shop-the-edits-on-the-go-cat1721316408',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '41yJZbjeypHH7Sdct8QDRy',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 9',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shop-the-edits-on-the-go-cat1721316408',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'ON THE GO',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5Msj8ACsLlmJwnDwtjhzei',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Mobile Nav (Where to Wear) 10',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '573k8qGUhLiNwFW0NRR11v',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Mobile Nav (Where to Wear) 10',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '1tHmgp1QktciYQtAZ2aZxx',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 10',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731365417/content/marketing/m_HP_Vacation_112524.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 165421,
                                            width: 284,
                                            format: 'jpg',
                                            height: 356,
                                            version: 1731365417,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                              retouch_status: '0001',
                                              star_rating: '0001',
                                            },
                                            public_id: 'content/marketing/m_HP_Vacation_112524',
                                            created_at: '2024-11-11T22:50:17Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1731365417/content/marketing/m_HP_Vacation_112524.jpg',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731365417/content/marketing/m_HP_Vacation_112524.jpg',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731365417/content/marketing/m_HP_Vacation_112524.jpg',
                                            raw_transformation: 'f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-vacation-cat82450741',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4HIRljDytBTEWzVCFWM3ke',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Mobile Nav (Where to Wear) 10',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-vacation-cat82450741',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'VACATION',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        parallaxEffect: 'Dynamic Horizontal Content Rail',
                      },
                    },
                  ],
                  verticalSpacingMobile: '8',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '2oUdG9OfZAt0MehMABJHBz',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage Fallback: Mobile Nav (The Edits)',
                  mobileWidth: '96',
                  isFullBleedLayout: true,
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'XUhdRjYuOjWaE6bscOZLZ',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's: Desktop Nav Lead-In (The Edits)",
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2WHATCFNzaulsFOUsi18J0',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: "Homepage Women's: Desktop Nav (The Edits)",
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'The Edits',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-4',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-2',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'The Edits',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              oneAppIsShowPlpDisclaimerPopup: false,
                            },
                          },
                        ],
                        verticalSpacingMobile: '0',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '4nBwaFYZizIk1QSQkd0rJT',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Fallback: Mobile Nav (The Edits)',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '30i1AZcuMkjC1KZ31yBssj',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 3",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5tj6WupCEsRvQF0wAAgaU',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Desktop Nav (The Edits) 3",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '68zxxzpf6L8PJu1qFFYzqA',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 3",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1728483589/content/stock/HERRERA_NODEPIC_080124_788_EXT.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 1750161,
                                            width: 1056,
                                            format: 'jpg',
                                            height: 1321,
                                            version: 1728483589,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                              retouch_status: '0001',
                                              star_rating: '0001',
                                            },
                                            public_id: 'content/stock/HERRERA_NODEPIC_080124_788_EXT',
                                            created_at: '2024-10-09T14:19:49Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1728483589/content/stock/HERRERA_NODEPIC_080124_788_EXT.jpg',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1728483589/content/stock/HERRERA_NODEPIC_080124_788_EXT.jpg',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1728483589/content/stock/HERRERA_NODEPIC_080124_788_EXT.jpg',
                                            raw_transformation: 'c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-dresses-cat43810733',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4Fyz98z9ApIT9AalxHVtkF',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 3",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-dresses-cat43810733',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'DRESSES',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '4zfacNZcE3sUsSaqNXLGqq',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Dresses',
                                          code: 'DA',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5srcxrOPpAifVjoTx0qJ4M',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 1",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2LSzVKf6UZLcwKEo85CD9N',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Desktop Nav (The Edits) 1",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '56U1JbjaPzCxP37l7LpeQ',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 1",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1728482994/content/stock/GORSKI_2_NODEPIC_073124_159_EXT.jpg',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 1271487,
                                            width: 1056,
                                            format: 'jpg',
                                            height: 1321,
                                            version: 1728482994,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                              retouch_status: '0001',
                                              star_rating: '0001',
                                            },
                                            public_id: 'content/stock/GORSKI_2_NODEPIC_073124_159_EXT',
                                            created_at: '2024-10-09T14:09:54Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1728482994/content/stock/GORSKI_2_NODEPIC_073124_159_EXT.jpg',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1728482994/content/stock/GORSKI_2_NODEPIC_073124_159_EXT.jpg',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1728482994/content/stock/GORSKI_2_NODEPIC_073124_159_EXT.jpg',
                                            raw_transformation: 'c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '43iknetaPZS8QAffVQwF6L',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 1",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'OUTERWEAR',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '6vq4aJqplk2odkk88vP5RP',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Outerwear',
                                          code: 'OW',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1QU48urEY0qFlDzxRSSZel',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 2",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1x853SbFaZuHl4LzpNlbHs',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Desktop Nav (The Edits) 2",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '7A9BuoakaxV1Y75QvXyAct',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 2",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_5600,w_5798,y_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good/v1726264790/content/creative/WEITZMAN_NODEPIC_081324_026COMP.tiff',
                                            tags: [
                                              'VOLUME STILLS PT.1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 126096492,
                                            width: 5798,
                                            format: 'tiff',
                                            height: 7247,
                                            version: 1726264790,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-08-12',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-08-12',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'rho185vmgblznspgvdsa',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Tate Boot|Tate Bootie',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| VOLUME STILLS PT.1',
                                                'Digital Tech': 'Peter Salisbury',
                                                Photographer: 'Nick Prendergast',
                                                Vendors: 'Stuart Weitzman',
                                              },
                                            },
                                            public_id: 'content/creative/WEITZMAN_NODEPIC_081324_026COMP',
                                            created_at: '2024-08-28T21:52:15Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_5600,w_5798,y_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good/v1726264790/content/creative/WEITZMAN_NODEPIC_081324_026COMP.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726264790/content/creative/WEITZMAN_NODEPIC_081324_026COMP.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726264790/content/creative/WEITZMAN_NODEPIC_081324_026COMP.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_5600,w_5798,y_4400/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-boots-cat45140734',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '79aXB7S5E7hai87hxyfRkc',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 2",
                                        desktopText: {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                                {
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-boots-cat45140734',
                                                  },
                                                  content: [
                                                    {
                                                      data: {},
                                                      marks: [],
                                                      value: 'BOOTS & BOOTIES',
                                                      nodeType: 'text',
                                                    },
                                                  ],
                                                  nodeType: 'hyperlink',
                                                },
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: '',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'paragraph',
                                            },
                                          ],
                                          nodeType: 'document',
                                        },
                                        mobileText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-boots-cat45140734',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'BOOTS &',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-boots-cat45140734',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'BOOTIES',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '4kdAZIhJs31qdxWUc6jYm0',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Shoes',
                                          code: 'SH',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3HDBiRnFrOgRYUAz4RG3oB',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Desktop Nav (The Edits) 4',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3g96e22JNaMoveXhC8a30T',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (The Edits) 4',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2lkp4wX9B0PFlqlQi64LSq',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (The Edits) 4',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_6200,w_6105/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726264760/content/creative/VALENTINO_NODEPIC_081324_010COMP.tiff',
                                            tags: [
                                              'VOLUME STILLS PT.1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 139827272,
                                            width: 6105,
                                            format: 'tiff',
                                            height: 7632,
                                            version: 1726264760,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-08-12',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-08-12',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'r2byaqh6ppzzhofx3apm',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Logo Shoulder Bag',
                                                'Art Director': 'Jonas Park',
                                                caption: 'HOLIDAY 25| VOLUME STILLS PT.1',
                                                Depictions: 'V6CL2',
                                                'Digital Tech': 'Peter Salisbury',
                                                Photographer: 'Nick Prendergast',
                                                'PIM Style': '4889534',
                                                Vendors: 'Valentino Garavani',
                                              },
                                            },
                                            public_id: 'content/creative/VALENTINO_NODEPIC_081324_010COMP',
                                            created_at: '2024-08-28T22:01:13Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_6200,w_6105/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1726264760/content/creative/VALENTINO_NODEPIC_081324_010COMP.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726264760/content/creative/VALENTINO_NODEPIC_081324_010COMP.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726264760/content/creative/VALENTINO_NODEPIC_081324_010COMP.tiff',
                                            raw_transformation: 'c_crop,g_south,h_6200,w_6105/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/handbags-all-handbags-crossbody-bags-cat42600741',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '7EcWLwRz434zOZUvTjifLj',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (The Edits) 4',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/handbags-all-handbags-crossbody-bags-cat42600741',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'CROSSBODY BAGS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5k4OTIzZqmeTEEaHUgB9SG',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage: Desktop Nav (The Edits) 5',
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '64V2pRwCpjEFI6vc5z44Ay',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: 'Homepage: Desktop Nav (The Edits) 5',
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '4z5wDBhAF44CUZ1E4INWi2',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (The Edits) 5',
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1727993645/content/creative/sisley_nodepic_082024_001_V2.tiff',
                                            tags: [],
                                            type: 'upload',
                                            bytes: 49360924,
                                            width: 4901,
                                            format: 'tiff',
                                            height: 6861,
                                            version: 1727993645,
                                            duration: null,
                                            metadata: {
                                              asset_type: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: '0001',
                                              brands: [
                                                '0001',
                                              ],
                                            },
                                            public_id: 'content/creative/sisley_nodepic_082024_001_V2',
                                            created_at: '2024-09-30T16:06:05Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1727993645/content/creative/sisley_nodepic_082024_001_V2.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727993645/content/creative/sisley_nodepic_082024_001_V2.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727993645/content/creative/sisley_nodepic_082024_001_V2.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/beauty-all-beauty-cat55180733',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2yTkn2TwgV9HyLlQ0oU2EU',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: 'Homepage: Desktop Nav (The Edits) 5',
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/beauty-all-beauty-cat55180733',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'BEAUTY & FRAGRANCES',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4gmV8OTb59EyKV4wDxSTRg',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 10",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2HMgJ63lwPym0ODez5N6bX',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Mobile Nav (The Edits) 10",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3NA7bqmN452TWYFkitcRu0',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 10",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_xy_center,h_330,w_264,y_4300/f_auto/q_auto:good/v1727216377/content/creative/FISHER_NODEPIC_073124_023.tiff',
                                            tags: [
                                              'VOLUME ON FIG PT. 1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 73021700,
                                            width: 5350,
                                            format: 'tiff',
                                            height: 8021,
                                            version: 1727216377,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-31',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-07-31',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Melange Fine Org Co Jersey Round Neck L/S Tee| Peruvian Org Cotton Alpaca Poncho| Woven Wool Silk Fl Pleated A Line Skirt',
                                                'Art Director': 'Peggy Bennett',
                                                caption: 'HOLIDAY 25| VOLUME ON FIG PT. 1',
                                                'Digital Tech': 'Joshua Martin',
                                                Photographer: 'Nick Prendergast',
                                                Vendors: 'Eileen Fisher',
                                              },
                                            },
                                            public_id: 'content/creative/FISHER_NODEPIC_073124_023',
                                            created_at: '2024-08-06T13:33:23Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_xy_center,h_330,w_264,y_4300/f_auto/q_auto:good/v1727216377/content/creative/FISHER_NODEPIC_073124_023.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727216377/content/creative/FISHER_NODEPIC_073124_023.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727216377/content/creative/FISHER_NODEPIC_073124_023.tiff',
                                            raw_transformation: 'c_fill,dpr_2.0,g_xy_center,h_330,w_264,y_4300/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-sweaters-cat41160752',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '7jURwLMN876Hx3IHNUXTab',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 10",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-sweaters-cat41160752',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'SWEATERS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: 'b99uP39ChXFanf51Su8wu',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Sweaters',
                                          code: 'SR',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1HcfOLe6m2kq62iyTQSGJm',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 4",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4Yl58qHrfVNrlnVvh3ufT0',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Desktop Nav (The Edits) 4",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2R644eVSDntCQ95sce8yiX',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 4",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_4500,w_4142/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2200/f_auto/q_auto:good/v1728098588/content/creative/goose_nodepic_061124_001_V1.tiff',
                                            tags: [
                                              'NY-HAPPILY EVER 24',
                                              'VOLUME STILLS + PARTS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 105789260,
                                            width: 4142,
                                            format: 'tiff',
                                            height: 5178,
                                            version: 1728098588,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-08-19',
                                              launch_date: '2024-12-01',
                                              shoot_date: '2024-08-19',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'vjydqvl42qtaarpaxuxf',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'lwlvaocntzgohrkdjuuf',
                                              ],
                                              categories: [
                                                'z68hxyregoh5b5ia0pnn',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Exclusive Sneaker| Exclusive Super Star Gold Glittered Suede W/Perf Star Lace Up| Exclusive Super Star Navy Suede W/Navy Glitter Star Lace Up| Exclusive Super Star Pale Pink Sneaker W/Pale Pink Allover Paillets Lace Up',
                                                'Art Director': 'Leslie Laudo',
                                                caption: 'HOLIDAY 25| NY-HAPPILY EVER',
                                                Depictions: 'X6MEE| X6ME8| X6MEA',
                                                'Digital Tech': 'Peter Salisbury',
                                                Photographer: 'Brian Coleman',
                                                'PIM Style': '4823997| 4823991| 4823993',
                                                Vendors: 'Golden Goose',
                                              },
                                            },
                                            public_id: 'content/creative/goose_nodepic_061124_001_V1',
                                            created_at: '2024-07-01T22:29:21Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_4500,w_4142/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2200/f_auto/q_auto:good/v1728098588/content/creative/goose_nodepic_061124_001_V1.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1728098588/content/creative/goose_nodepic_061124_001_V1.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1728098588/content/creative/goose_nodepic_061124_001_V1.tiff',
                                            raw_transformation: 'c_crop,h_4500,w_4142/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_2200/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '3l4X2vBbskX6sqYPrjvdEs',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Desktop Nav (The Edits) 4",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'SNEAKERS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '2AMO8WHiuydUvgt33g87nI',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Sneakers',
                                          code: 'Sneakers',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3RhRqVTs1mO501tgF0YIOL',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 8",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2fP9Idsn4V3rxpuVybqwvb',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Mobile Nav (The Edits) 8",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5Y3TQvA1f1yp7igT78FXWJ',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 8",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_5800,w_5350/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good/v1727217142/content/creative/MARA_NODEPIC_073124_028.tiff',
                                            tags: [
                                              'VOLUME ON FIG PT. 1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 128762112,
                                            width: 5350,
                                            format: 'tiff',
                                            height: 8021,
                                            version: 1727217142,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-07-31',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-07-31',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'hbde6kjohubvv01t6rmk',
                                                '0001',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Morris Suede Leather Top| Pomezia Mini Skirt',
                                                'Art Director': 'Peggy Bennett',
                                                caption: 'HOLIDAY 25| VOLUME ON FIG PT. 1',
                                                'Digital Tech': 'Joshua Martin',
                                                Photographer: 'Nick Prendergast',
                                                Vendors: 'Max Mara',
                                              },
                                            },
                                            public_id: 'content/creative/MARA_NODEPIC_073124_028',
                                            created_at: '2024-08-06T19:45:47Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,h_5800,w_5350/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good/v1727217142/content/creative/MARA_NODEPIC_073124_028.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727217142/content/creative/MARA_NODEPIC_073124_028.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727217142/content/creative/MARA_NODEPIC_073124_028.tiff',
                                            raw_transformation: 'c_crop,h_5800,w_5350/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3000/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-tops-cat42960827',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '69XU3kliNbbykTKJopD1Fy',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 8",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-tops-cat42960827',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'TOPS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '7BqUEzqDOdvfkphnu3c03w',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Tops',
                                          code: 'TP',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6hzGS7M9NGcqAeVFFxHLe0',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 9",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '7cW0K2zPCSLGaSDe6RSIUL',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Mobile Nav (The Edits) 9",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5WicHo5HGVjbdowBhyfzet',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 9",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1721768696/content/creative/choo_nodepic_061124_003_V1.tiff',
                                            tags: [
                                              'VOLUME STILLS + PARTS 24',
                                            ],
                                            type: 'upload',
                                            bytes: 59616132,
                                            width: 4985,
                                            format: 'tiff',
                                            height: 6232,
                                            version: 1721768696,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-06-12',
                                              shoot_date: '2024-06-12',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: 'jkvyc553vnyzofslenmd',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'lwlvaocntzgohrkdjuuf',
                                              ],
                                              categories: [
                                                'rho185vmgblznspgvdsa',
                                                'r2byaqh6ppzzhofx3apm',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'BING 100|Bon Bon',
                                                'Art Director': 'Leslie Laudo',
                                                caption: 'FALL DIGITAL 25| VOLUME STILLS + PARTS',
                                                Depictions: 'X6LDY|V688A',
                                                'Digital Tech': 'Peter Salisbury',
                                                Photographer: 'Brian Coleman',
                                                'PIM Style': '4797460|4798805',
                                                Vendors: 'Jimmy Choo',
                                              },
                                            },
                                            public_id: 'content/creative/choo_nodepic_061124_003_V1',
                                            created_at: '2024-07-01T22:36:00Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good/v1721768696/content/creative/choo_nodepic_061124_003_V1.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1721768696/content/creative/choo_nodepic_061124_003_V1.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1721768696/content/creative/choo_nodepic_061124_003_V1.tiff',
                                            raw_transformation: 'c_scale,dpr_2.0,h_330,w_264/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-pumps-heels-cat68670755',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '5wzXIqew7U6cb20oyAzsbQ',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 9",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-pumps-heels-cat68670755',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'PUMPS & HEELS',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '245fyOyyuLCufYeDSrpPCk',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Pumps',
                                          code: 'PM',
                                          type: 'Category',
                                        },
                                      },
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '6lge7JnP3RZttLXfEoLLQt',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Heels',
                                          code: 'Heel',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5jT2Q07jtU6cehoGDaWgGd',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's: Mobile Nav (The Edits) 7",
                              mobileWidth: '40',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '63XBR2Ht86g3bFZk5vdLis',
                                  },
                                  contentType: 'textWithImageContentAsset',
                                  fields: {
                                    name: "Homepage Women's: Mobile Nav (The Edits) 7",
                                    image: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2wJ76hg8iqCerjKGjqDCVT',
                                      },
                                      contentType: 'imageContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 7",
                                        isLazyLoaded: true,
                                        parallaxShiftZoomBehavior: false,
                                        desktopImage: [
                                          {
                                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6400,w_5464,y_4800/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3400/f_auto/q_auto:good/v1727217263/content/creative/YURMAN_NODEPIC_080224_2826.tiff',
                                            tags: [
                                              'VOLUME ON FIG PT. 1 24',
                                            ],
                                            type: 'upload',
                                            bytes: 134311660,
                                            width: 5464,
                                            format: 'tiff',
                                            height: 8192,
                                            version: 1727217263,
                                            duration: null,
                                            metadata: {
                                              expiration_date: '2025-08-02',
                                              launch_date: '2024-10-15',
                                              shoot_date: '2024-08-02',
                                              asset_type: 'uymo684qvl8awfg52vni',
                                              composition: '0001',
                                              retouch_status: 'we2rathfmx9tfp0tefy6',
                                              star_rating: 'wkkermich0rjr7exzmar',
                                              brands: [
                                                'o6ubpongsfhteehpxwlf',
                                              ],
                                              campaign_types: [
                                                'uwnr8vduk57hpk8tenso',
                                              ],
                                              categories: [
                                                'vpqoyqkbgk8ejrorvep6',
                                              ],
                                            },
                                            context: {
                                              custom: {
                                                alt: 'Holiday Book - 5.5mm Sculpted Cbl Cuff Bracelet 18k| Holiday Book - 7mm Sculpted Cable Cuff Cuff Brac Bd Blk Titan| Holiday Book - 9mm Sculpted Cable Band Ring 18k| Volume - 4.5mm Pave Sculpted Cblbracelet Di 18k| Volume - 6mm Sculpted Cablebracelet 18k| Volume- 13mm Sculpted Cable Pinky Ring 18k Di',
                                                'Art Director': 'Peggy Bennett',
                                                caption: 'HOLIDAY 25| VOLUME ON FIG PT. 1',
                                                Depictions: 'NC60B| NC60A| N9JZV| PG8P4| P688M| PJ8GW',
                                                'Digital Tech': 'David Nix',
                                                Photographer: 'Nick Prendergast',
                                                'PIM Style': '4813767| 4813766| 4336417| 4652837| 4773688| 4698483',
                                                Vendors: 'David Yurman',
                                              },
                                            },
                                            public_id: 'content/creative/YURMAN_NODEPIC_080224_2826',
                                            created_at: '2024-08-06T19:51:13Z',
                                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6400,w_5464,y_4800/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3400/f_auto/q_auto:good/v1727217263/content/creative/YURMAN_NODEPIC_080224_2826.tiff',
                                            resource_type: 'image',
                                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1727217263/content/creative/YURMAN_NODEPIC_080224_2826.tiff',
                                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1727217263/content/creative/YURMAN_NODEPIC_080224_2826.tiff',
                                            raw_transformation: 'c_crop,g_xy_center,h_6400,w_5464,y_4800/c_fill,dpr_2.0,g_xy_center,h_330,w_264,x_3400/f_auto/q_auto:good',
                                          },
                                        ],
                                        linkTo: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760',
                                      },
                                    },
                                    text: {
                                      sys: {
                                        type: 'Link',
                                        linkType: 'Entry',
                                        id: '2H5VrJr0GOeZ8rSjiUt4AD',
                                      },
                                      contentType: 'richTextContentAsset',
                                      fields: {
                                        name: "Homepage Women's: Mobile Nav (The Edits) 7",
                                        desktopText: {
                                          nodeType: 'document',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'paragraph',
                                              data: {},
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                                {
                                                  nodeType: 'hyperlink',
                                                  data: {
                                                    uri: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760',
                                                  },
                                                  content: [
                                                    {
                                                      nodeType: 'text',
                                                      value: 'JEWELRY & ACCESSORIES',
                                                      marks: [],
                                                      data: {},
                                                    },
                                                  ],
                                                },
                                                {
                                                  nodeType: 'text',
                                                  value: '',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        textJustification: 'Center',
                                        oneAppIsShowPlpDisclaimerPopup: false,
                                      },
                                    },
                                    desktopTextPosition: 'Bottom',
                                    desktopTextVerticalAlignment: 'Middle',
                                    trackingTags: [
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '11zUBK8ocYs3oO1Q7g3oFW',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Jewelry',
                                          code: 'JW',
                                          type: 'Category',
                                        },
                                      },
                                      {
                                        sys: {
                                          type: 'Link',
                                          linkType: 'Entry',
                                          id: '1oIbb9x5dxxKeEFGQY5bxD',
                                        },
                                        contentType: 'trackingTags',
                                        fields: {
                                          name: 'Accessories',
                                          code: 'AC',
                                          type: 'Category',
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              topSpacingMobile: '24',
                            },
                          },
                        ],
                        parallaxEffect: 'Dynamic Horizontal Content Rail',
                      },
                    },
                  ],
                  verticalSpacingMobile: '8',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: 'XnyooY2EnpDOVLtzo8dLY',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage Fallback Desktop: Row 2',
                  desktopWidth: '90',
                  mobileWidth: '96',
                  isFullBleedLayout: true,
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2dhmBK5SSEJjSOBIcBMoZQ',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's Desktop: Row 2 Title",
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3rvTuZb41Zu99cyOrV5Owy',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: "Homepage Women's Desktop: Row 2 Title",
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Things We Love',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-4',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-2',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Things We Love',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                            },
                          },
                        ],
                        verticalSpacingMobile: '16',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '1uUhBCOPOF2aAsfk2vJvfF',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [1]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5XRtEpRTthQUG7kFA2XZz5',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [1] Image',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4Sesm4hjdWupQdMUD1YqFl',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [1] Image',
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726093258/content/creative/OLIVIA_NODEPIC_071524_0229.tiff',
                                        tags: [
                                          'TREAT YOURSELF ON FIG 24',
                                        ],
                                        type: 'upload',
                                        bytes: 305295964,
                                        width: 8736,
                                        format: 'tiff',
                                        height: 11648,
                                        context: {
                                          custom: {
                                            alt: 'Sibella Sequin Strapless Draped Bow Back Mini Dress',
                                            Vendors: 'Alice + Olivia',
                                            caption: 'HOLIDAY 25| TREAT YOURSELF ON FIG',
                                            'Art Director': 'Christopher Miller',
                                            'Digital Tech': 'Andrea Fremiotti',
                                            Photographer: 'Marc Hibbert',
                                          },
                                        },
                                        version: 1726093258,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          asset_type: 'uymo684qvl8awfg52vni',
                                          categories: [
                                            '0001',
                                          ],
                                          shoot_date: '2024-07-15',
                                          composition: '0001',
                                          launch_date: '2024-11-10',
                                          star_rating: 'wkkermich0rjr7exzmar',
                                          campaign_types: [
                                            'uwnr8vduk57hpk8tenso',
                                          ],
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                          expiration_date: '2025-07-15',
                                        },
                                        public_id: 'content/creative/OLIVIA_NODEPIC_071524_0229',
                                        created_at: '2024-07-25T14:02:04Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726093258/content/creative/OLIVIA_NODEPIC_071524_0229.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726093258/content/creative/OLIVIA_NODEPIC_071524_0229.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726093258/content/creative/OLIVIA_NODEPIC_071524_0229.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731?priorityProdId=prod275820418',
                                    shoppableProducts: [
                                      'prod275820418',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3JS6WFAXNIUr9HLV6Vf7io',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [1] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '78lIR00NSQomDkBAPs57wY',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [1] Copy',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731?priorityProdId=prod275820418',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'TAKE A BOW',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731?priorityProdId=prod275820418',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Make a dramatic entrance in minidresses with oversized bows from Alice + Olivia',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '  ',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3CV971kqijrYv73LzNd3qL',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [1] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '22xOgSItsuSbpaXg7e0slW',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [1] CTA',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/new-now-wear-to-night-out-cat85350731?priorityProdId=prod275820418',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'SHOP NIGHT OUT',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5Ijv7gDUdJP1g6uU7DX4al',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Row 2a',
                              code: '2A',
                              type: '3rd Group',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '5bX9xR9CyswfM8r442faoZ',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [2]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3mHZIfyrRlSm1OzgWHBTOj',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Row 2 [2] Image",
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6s4f4QRlLFQyQOKAZIlIgu',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Row 2 [2] Image",
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6000,w_5657,y_4400/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1730309849/content/creative/AZLEE_NODEPIC_073124_016COMP.tiff',
                                        tags: [
                                          'GIFTING 24',
                                        ],
                                        type: 'upload',
                                        bytes: 274509540,
                                        width: 5657,
                                        format: 'tiff',
                                        height: 7286,
                                        context: {
                                          custom: {
                                            alt: 'Azlee | Harwell Godfrey| Moritz Glik| Retrouvai| Sorellina|',
                                            Vendors: 'Emerald & Trillion Small Diamond Charm| Large Motif Double Link Pave Toggle Chain| Sunburst Large Coin| 18" Mini Diamond Hexagon Foundation Chain| Major Lucky Salt Shaker Charm Pendant| Mola 15 Necklace; 1.25ct Of Diamond Enclosed In A Kaleidoscope Shaker™ In 18k Gold| Multi Pendat Page Placeholder – Placeholder| Signature Classicmatchbook Pendant| 18k Yellow Gold Smooth Teardrop Shape Chain Necklace With 0.09 Cttw G-Hsi Diamonds. 18-Inches| Ooak 18k Yellow Gold Pendant With 3.07 Ctw Seafoam Green Tourmaline, 1.40 Cttw Purple Spinel, And 0.28 Cttw G-Hsi Diamonds. 22x15 Mm. Pendant-Only|',
                                            caption: 'BEJEWELED 25| GIFTING',
                                            'PIM Style': '4413250| 4882494| 4807291| 4879170| 4879163| 4878292| 4884810| 4875809| 4875799',
                                            Depictions: 'PLQ0L| PL8Z3| PLPBD| PLPBC| PLP72| PLNTP',
                                            'Art Director': 'Devin Hall',
                                            'Digital Tech': 'Peter Salisbury',
                                            Photographer: 'Jeff Stephens',
                                          },
                                        },
                                        version: 1730309849,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          channels: [
                                            'c6gkcb2srckettgdzfc8',
                                          ],
                                          asset_type: 'uymo684qvl8awfg52vni',
                                          categories: [
                                            'vpqoyqkbgk8ejrorvep6',
                                          ],
                                          shoot_date: '2024-07-31',
                                          composition: 'jkvyc553vnyzofslenmd',
                                          launch_date: '2024-11-04',
                                          star_rating: 'wkkermich0rjr7exzmar',
                                          campaign_types: [
                                            'uwnr8vduk57hpk8tenso',
                                          ],
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                          expiration_date: '2025-07-31',
                                        },
                                        public_id: 'content/creative/AZLEE_NODEPIC_073124_016COMP',
                                        created_at: '2024-09-03T18:23:35Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6000,w_5657,y_4400/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1730309849/content/creative/AZLEE_NODEPIC_073124_016COMP.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1730309849/content/creative/AZLEE_NODEPIC_073124_016COMP.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_crop,g_xy_center,h_6000,w_5657,y_4400/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1730309849/content/creative/AZLEE_NODEPIC_073124_016COMP.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760?priorityProdId=prod275180318,prod274730266,prod270143287,prod274850234',
                                    shoppableProducts: [
                                      'prod275180318',
                                      'prod274730266',
                                      'prod270143287',
                                      'prod274850234',
                                      'prod271780101',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4vVjgeBEq1PijE0PPepi8f',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [2] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4yFdbIogDctzTFpNfsq7Y',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [2] Copy',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760?priorityProdId=prod275180318,prod274730266,prod270143287,prod274850234',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'PERFECT MATCH',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760?priorityProdId=prod275180318,prod274730266,prod270143287,prod274850234',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Necklaces with unconventional pendants to wear every day',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: ' ',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3GQlBIABfDQyemuqhy4sDo',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [2] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: 'kmEKIzZBm2EUrm6NRJ6hI',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [2] CTA',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/jewelry-accessories-all-jewelry-accessories-cat84890760?priorityProdId=prod275180318,prod274730266,prod270143287,prod274850234',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'SHOP JEWELRY',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '3hqsx8LCbZzvikJfJ0fsU0',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [3]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '29sIpoyNMRNsudJO2r3kFb',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [3]',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '7m7xFXfZCTO94OGDOp7SUp',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [3]',
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_7200,w_6177/c_fill,g_xy_center,h_605,w_468,x_3200/f_auto/q_auto:good/v1730937412/content/creative/LAURENT_NODEPIC_081424_008_V2.tiff',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 76660732,
                                        width: 6177,
                                        format: 'tiff',
                                        height: 7721,
                                        version: 1730937412,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            '0001',
                                          ],
                                          asset_type: '0001',
                                          star_rating: '0001',
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                        },
                                        public_id: 'content/creative/LAURENT_NODEPIC_081424_008_V2',
                                        created_at: '2024-11-04T17:20:43Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_7200,w_6177/c_fill,g_xy_center,h_605,w_468,x_3200/f_auto/q_auto:good/v1730937412/content/creative/LAURENT_NODEPIC_081424_008_V2.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1730937412/content/creative/LAURENT_NODEPIC_081424_008_V2.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_crop,g_south,h_7200,w_6177/c_fill,g_xy_center,h_605,w_468,x_3200/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1730937412/content/creative/LAURENT_NODEPIC_081424_008_V2.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/designers-saint-laurent-womens-cat82440777?priorityProdId=prod266080259',
                                    shoppableProducts: [
                                      'prod266080259',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2wyD7KHrHComW15d9TyYGa',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [3] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2A024lh13WIMhjeKEY3PLg',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [3] Copy',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-saint-laurent-womens-cat82440777?priorityProdId=prod266080259',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'NEW ICONS',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-saint-laurent-womens-cat82440777?priorityProdId=prod266080259',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Shoulder bags that hold everything in go-with-anything hues from Saint Laurent',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: ' ',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'sxwdIrfqmyMGyaTxmPk3D',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [3] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6LhvLB3Gd8yQ412tYS2CYc',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [3] CTA',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-saint-laurent-womens-cat82440777?priorityProdId=prod266080259',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'SHOP SAINT LAURENT',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4kdAZIhJs31qdxWUc6jYm0',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Shoes',
                              code: 'SH',
                              type: 'Category',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '74a93b1U8gvLzq6umGtr86',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Prada',
                              code: 'PRAD',
                              type: 'Vendor',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5Ijv7gDUdJP1g6uU7DX4al',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Row 2a',
                              code: '2A',
                              type: '3rd Group',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2gOAMY0E8Cd0JWMQIaa8Os',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [4]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5hXictVfq2MfyL6XvVHFRc',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [4]',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2Zqm8Zti8QoR4PYmjM6iHQ',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [4]',
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1731021929/content/creative/LOEWE_NODEPIC_091824_1311_V2.tiff',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 57887036,
                                        width: 5637,
                                        format: 'tiff',
                                        height: 7046,
                                        version: 1731021929,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            '0001',
                                          ],
                                          asset_type: '0001',
                                          star_rating: '0001',
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                        },
                                        public_id: 'content/creative/LOEWE_NODEPIC_091824_1311_V2',
                                        created_at: '2024-11-06T21:30:50Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1731021929/content/creative/LOEWE_NODEPIC_091824_1311_V2.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1731021929/content/creative/LOEWE_NODEPIC_091824_1311_V2.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1731021929/content/creative/LOEWE_NODEPIC_091824_1311_V2.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731?priorityProdId=prod276660449,prod266910252,prod276900158,prod274130348',
                                    shoppableProducts: [
                                      'prod276660449',
                                      'prod266910252',
                                      'prod276900158',
                                      'prod274130348',
                                      'prod276392288',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3Vnk8uigbt2Hkq32qZun0R',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [4] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '7cjUlukwQFox8egzX8kcx8',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [4] Copy',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731?priorityProdId=prod276660449,prod266910252,prod276900158,prod274130348',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'ON TRACK',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'heading-6',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731?priorityProdId=prod276660449,prod266910252,prod276900158,prod274130348',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Sport-inspired sneakers from Christian Louboutin, Amiri, Chloé & more top designers',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: ' ',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2E0PkJR7bdsyGOqtn8mphR',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [4] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3zzMkj0wUPiZESvboXvvWL',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [4] CTA',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-sneakers-cat36450731?priorityProdId=prod276660449,prod266910252,prod276900158,prod274130348',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'SHOP SNEAKERS',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '4DjPaA5CNXS7S4RpA1tspw',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [5]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '715p01hT4JNDfgeAq16vJK',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [5]',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '42F5yA7hsRsHLrCD8zmfpp',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [5]',
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6000,w_4631,y_3652/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726178112/content/creative/mfk_nodepic_081924_001.tiff',
                                        tags: [
                                          'VOLUME BEAUTY 24',
                                        ],
                                        type: 'upload',
                                        bytes: 90113588,
                                        width: 4631,
                                        format: 'tiff',
                                        height: 6484,
                                        context: {
                                          custom: {
                                            alt: 'Baccarat Rouge 540 Candle| Baccarat Rouge 540 EDP| Baccarat Rouge 540 EXT| Baccarat Rouge 540 Hair Mist| Baccarat Rouge 540 Hand and Body Cleansing Gel| Baccarat Rouge 540 Sparkling Body Oil',
                                            Vendors: 'Maison Francis Kurkdjian',
                                            caption: 'HOLIDAY 25| VOLUME BEAUTY',
                                            'PIM Style': '2774820| 1692736| 2330650| 4659864| 4659865 4667017',
                                            Depictions: 'HBMV6| C1ZZR| C4LTY| C629U| C629V| C62NM',
                                            'Art Director': 'Devin Hall',
                                            'Digital Tech': 'Jeff Stephens',
                                            Photographer: 'Brian Coleman',
                                          },
                                        },
                                        version: 1726178112,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          asset_type: 'uymo684qvl8awfg52vni',
                                          categories: [
                                            'jiajmknabaz4krln8t26',
                                          ],
                                          shoot_date: '2024-08-19',
                                          composition: 'jkvyc553vnyzofslenmd',
                                          launch_date: '2024-10-15',
                                          star_rating: 'wkkermich0rjr7exzmar',
                                          campaign_types: [
                                            'uwnr8vduk57hpk8tenso',
                                          ],
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                          expiration_date: '2025-08-19',
                                        },
                                        public_id: 'content/creative/mfk_nodepic_081924_001',
                                        created_at: '2024-08-23T20:47:18Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_6000,w_4631,y_3652/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726178112/content/creative/mfk_nodepic_081924_001.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726178112/content/creative/mfk_nodepic_081924_001.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_crop,g_xy_center,h_6000,w_4631,y_3652/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726178112/content/creative/mfk_nodepic_081924_001.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/designers-maison-francis-kurkdjian-cat24260734?priorityProdId=prod214710128,prod186890028,prod203310173,prod266080049',
                                    shoppableProducts: [
                                      'prod214710128',
                                      'prod214710128',
                                      'prod266080055',
                                      'prod186890028',
                                      'prod203310173',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '65KxfWod890bDmposoH0IY',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [5] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '2qB2tNFa2jzq5mpp62mmN8',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [5] Copy',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-maison-francis-kurkdjian-cat24260734?priorityProdId=prod214710128,prod186890028,prod203310173,prod266080049',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'SCENT STORY',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-maison-francis-kurkdjian-cat24260734?priorityProdId=prod214710128,prod186890028,prod203310173,prod266080049',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Discover new interpretations of classic fragrances from Maison Francis Kurkdjian',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: ' ',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7iHWrB1uJhpYUDa71QaSrx',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [5] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4qCpRf6LfITuOMwKiubIfB',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [5] CTA',
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-maison-francis-kurkdjian-cat24260734?priorityProdId=prod214710128,prod186890028,prod203310173,prod266080049',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'SHOP MAISON FRANCIS KURKDJIAN',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/designers-maison-francis-kurkdjian-cat24260734?priorityProdId=prod214710128,prod186890028,prod203310173,prod266080049',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'SHOP NOW',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'DnNlQIwEqq12YzWPmWqsY',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage Desktop: Row 2 [6]',
                        desktopWidth: '33',
                        mobileWidth: '50',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6lThYB4Gu8B4cLlayhLBqf',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [6]',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1s3poCw0yaxpLMWDf8Kcx2',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [6]',
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_6800,w_9901/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726602525/content/creative/GOOSE_NODEPIC_071724_302_A.tiff',
                                        tags: [
                                          'COLD WEATHER 24',
                                        ],
                                        type: 'upload',
                                        bytes: 296881500,
                                        width: 9901,
                                        format: 'tiff',
                                        height: 7426,
                                        context: {
                                          custom: {
                                            alt: 'Spessa Cropped Jacket Baby Pink',
                                            Vendors: 'Canada Goose',
                                            caption: 'HOLIDAY 25| COLD WEATHER',
                                            'PIM Style': '4879784',
                                            Depictions: 'T613P',
                                            'Art Director': 'Christopher Miller',
                                            'Digital Tech': 'Tadaaki Shibuya',
                                            Photographer: 'Christian MacDonald',
                                          },
                                        },
                                        version: 1726602525,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          channels: [
                                            'c6gkcb2srckettgdzfc8',
                                          ],
                                          asset_type: 'uymo684qvl8awfg52vni',
                                          categories: [
                                            '0001',
                                          ],
                                          shoot_date: '2024-07-17',
                                          composition: '0001',
                                          launch_date: '2024-10-21',
                                          star_rating: 'wkkermich0rjr7exzmar',
                                          campaign_types: [
                                            'uwnr8vduk57hpk8tenso',
                                          ],
                                          retouch_status: 'we2rathfmx9tfp0tefy6',
                                          expiration_date: '2025-07-17',
                                        },
                                        public_id: 'content/creative/GOOSE_NODEPIC_071724_302_A',
                                        created_at: '2024-07-30T15:38:34Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_6800,w_9901/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good/v1726602525/content/creative/GOOSE_NODEPIC_071724_302_A.tiff',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1726602525/content/creative/GOOSE_NODEPIC_071724_302_A.tiff',
                                        resource_type: 'image',
                                        raw_transformation: 'c_crop,g_south,h_6800,w_9901/c_fill,dpr_2.0,h_605,w_468/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1726602525/content/creative/GOOSE_NODEPIC_071724_302_A.tiff',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744?priorityProdId=prod274850168',
                                    shoppableProducts: [
                                      'prod274850168',
                                    ],
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '24',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4RiaQAS9eE6vnBe2IX7u1I',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [6] Copy',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1I3bw8U8TdcdU0DsN71VJp',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [6] Copy',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744?priorityProdId=prod274850168',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'NORTHERN LIGHTS',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'heading-6',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744?priorityProdId=prod274850168',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Ward off the cold in signature performance puffers from Canada Goose',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: ' ',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                              horizontalSpacingDesktop: '16',
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '0',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4L7QMJ7CrKQtDF9lOFE3Pe',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: 'Homepage Desktop: Row 2 [6] CTA',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5tD5c1K4eSAxFHswsAHIWv',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: 'Homepage Desktop: Row 2 [6] CTA',
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-coats-cat77260744?priorityProdId=prod274850168',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'SHOP OUTERWEAR',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                    oneAppIsShowPlpDisclaimerPopup: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        verticalSpacingDesktop: '48',
                        verticalSpacingMobile: '24',
                      },
                    },
                  ],
                  verticalSpacingDesktop: '32',
                  verticalSpacingMobile: '32',
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '40QSfckNnft51nh56hQzB9',
                },
                contentType: 'l2Layout',
                fields: {
                  name: "Homepage Women's Mobile: Services",
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'flpQ6AOgoWHj28paHVd38',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's Desktop: Services 1",
                        desktopWidth: '33',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6uXENA8L8ukFuCWIo4v2oo',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 1 Icon",
                              desktopWidth: '80',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '38eHG19O6sbzVVeWszsSJ8',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 1 Icon",
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_38/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_SA_082123.png',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 2583,
                                        width: 80,
                                        format: 'png',
                                        height: 80,
                                        version: 1692133403,
                                        duration: null,
                                        metadata: {
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          channels: [
                                            'zux26btuerj5pm8cd76l',
                                          ],
                                          asset_type: 'gnwvisghth8lacvzdjkm',
                                          star_rating: '0001',
                                          retouch_status: '0001',
                                        },
                                        public_id: 'content/stock/HP_Services_SA_082123',
                                        created_at: '2023-08-15T21:03:23Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_38/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_SA_082123.png',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_SA_082123.png',
                                        resource_type: 'image',
                                        raw_transformation: 'c_scale,dpr_2.0,h_38,w_38/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_SA_082123.png',
                                      },
                                    ],
                                    mobileImage: [],
                                    linkTo: 'https://www.neimanmarcus.com/c/cat78570743',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '8',
                              verticalSpacingMobile: '8',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7v4EE9Zclvho39ECUh3qqv',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 1 Text",
                              desktopWidth: '96',
                              mobileWidth: '96',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4jLCzkcZzQ579BZb1PPRGS',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 1 Text",
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Shop with Style Advisors',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'From styling advice to tracking down a specific item, our style advisors are ready to assist',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'heading-4',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Shop with Style Advisors',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'From styling advice to tracking down a specific item, our style advisors are ready to assist',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3hUCi2NXnBmYiMGwaHD1Qo',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 1 CTA",
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5Zu97yx4FYMCNEnmwg9sx2',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 1 CTA",
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'FIND YOUR MATCH',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/cat78570743',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'FIND YOUR MATCH',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5nnVBFPRKRisVRnODhZjOn',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Style Advisors',
                              code: 'SD',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6ibYTXW9Zwo70wptvlevXr',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's Desktop: Services 2",
                        desktopWidth: '33',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2hYWARkQhUg4SGx18cWbz4',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 2 Icon",
                              desktopWidth: '80',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '3dzh6Vrz5P6IKPtqlPEsk7',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 2 Icon",
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_42/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_Fashionphile_082123.png',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 1730,
                                        width: 84,
                                        format: 'png',
                                        height: 76,
                                        version: 1692133403,
                                        duration: null,
                                        metadata: {
                                          asset_type: 'gnwvisghth8lacvzdjkm',
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          channels: [
                                            'zux26btuerj5pm8cd76l',
                                          ],
                                          retouch_status: '0001',
                                          star_rating: '0001',
                                        },
                                        public_id: 'content/stock/HP_Services_Fashionphile_082123',
                                        created_at: '2023-08-15T21:03:23Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_42/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_Fashionphile_082123.png',
                                        resource_type: 'image',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_Fashionphile_082123.png',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_Fashionphile_082123.png',
                                        raw_transformation: 'c_scale,dpr_2.0,h_38,w_42/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '8',
                              verticalSpacingMobile: '8',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6CZUJP5NbESoPE7d7Uk8cE',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 2 Text",
                              desktopWidth: '96',
                              mobileWidth: '96',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '7IWfYo9WPLjbpy2Uu6I43j',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 2 Text",
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Fashionphile',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'We’ve partnered with Fashionphile to make reselling your accessories easy',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'heading-4',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Fashionphile',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'We’ve partnered with Fashionphile to make reselling your accessories easy',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7I8XDL1CimzqvBTiZE8chK',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 2 CTA",
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6jZ0TWMTlGaNGa2QYRbULE',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 2 CTA",
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'FIND OUT MORE',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/c/handbags-new-now-sell-your-handbag-cat74610735',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'FIND OUT MORE',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'z6TlwpvKN98YxmRCetQta',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Fashionphile',
                              code: 'FP',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '45YbOIdXfWFAwB02uQ4uyO',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Homepage Women's Desktop: Services 3",
                        desktopWidth: '33',
                        l4Layout: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4sSF5pq6ejT5KVcOlAgVsy',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 3 Icon",
                              desktopWidth: '80',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '6cTwRp0ZFBxlq9E9juyLjo',
                                  },
                                  contentType: 'imageContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 3 Icon",
                                    isLazyLoaded: true,
                                    parallaxShiftZoomBehavior: false,
                                    desktopImage: [
                                      {
                                        url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_35/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_Tailoring_082123.png',
                                        tags: [],
                                        type: 'upload',
                                        bytes: 3180,
                                        width: 80,
                                        format: 'png',
                                        height: 86,
                                        version: 1692133403,
                                        duration: null,
                                        metadata: {
                                          asset_type: 'gnwvisghth8lacvzdjkm',
                                          brands: [
                                            'o6ubpongsfhteehpxwlf',
                                          ],
                                          channels: [
                                            'zux26btuerj5pm8cd76l',
                                          ],
                                          retouch_status: '0001',
                                          star_rating: '0001',
                                        },
                                        public_id: 'content/stock/HP_Services_Tailoring_082123',
                                        created_at: '2023-08-15T21:03:23Z',
                                        secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_38,w_35/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good/v1692133403/content/stock/HP_Services_Tailoring_082123.png',
                                        resource_type: 'image',
                                        original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_Tailoring_082123.png',
                                        original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692133403/content/stock/HP_Services_Tailoring_082123.png',
                                        raw_transformation: 'c_scale,dpr_2.0,h_38,w_35/c_pad,dpr_2.0,h_38,w_405/f_auto/q_auto:good',
                                      },
                                    ],
                                    linkTo: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '8',
                              verticalSpacingMobile: '8',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7dNGQNhT8KfsKTMWg0Dkkb',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 3 Text",
                              desktopWidth: '96',
                              mobileWidth: '96',
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: 'kmIENgI7idGG8kdJrVOnf',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 3 Text",
                                    desktopText: {
                                      data: {},
                                      content: [
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Tailored to You',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'heading-6',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                        {
                                          data: {},
                                          content: [
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                            {
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                              },
                                              content: [
                                                {
                                                  data: {},
                                                  marks: [],
                                                  value: 'Find your perfect fit with the help of our in-house experts',
                                                  nodeType: 'text',
                                                },
                                              ],
                                              nodeType: 'hyperlink',
                                            },
                                            {
                                              data: {},
                                              marks: [],
                                              value: '',
                                              nodeType: 'text',
                                            },
                                          ],
                                          nodeType: 'paragraph',
                                        },
                                      ],
                                      nodeType: 'document',
                                    },
                                    mobileText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'heading-4',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Tailored to You',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'Find your perfect fit with the help of our in-house experts',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                  },
                                },
                              ],
                              verticalSpacingDesktop: '16',
                              verticalSpacingMobile: '16',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6aA4iGXUFg31iFOikuI4H4',
                            },
                            contentType: 'l4Layout',
                            fields: {
                              name: "Homepage Women's Desktop: Services 3 CTA",
                              components: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '4MxZSGlowNzrurJ2WJSIeD',
                                  },
                                  contentType: 'richTextContentAsset',
                                  fields: {
                                    name: "Homepage Women's Desktop: Services 3 Text",
                                    desktopText: {
                                      nodeType: 'document',
                                      data: {},
                                      content: [
                                        {
                                          nodeType: 'paragraph',
                                          data: {},
                                          content: [
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                            {
                                              nodeType: 'hyperlink',
                                              data: {
                                                uri: 'https://www.neimanmarcus.com/editorial/services/alterations-tailoring-faq/',
                                              },
                                              content: [
                                                {
                                                  nodeType: 'text',
                                                  value: 'FIND OUT MORE',
                                                  marks: [],
                                                  data: {},
                                                },
                                              ],
                                            },
                                            {
                                              nodeType: 'text',
                                              value: '',
                                              marks: [],
                                              data: {},
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    textJustification: 'Center',
                                    buttonStyle: 'underlined',
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1LXk6Li5p5gMYiD6fzcA6p',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Tailoring',
                              code: 'TL',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  parallaxEffect: 'Dynamic Horizontal Content Rail',
                  trackingTags: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6N4j6GC5wxlKxVMmSzJdYT',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Row 4',
                        code: 'R4',
                        type: '3rd Group',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2VNpLnDiJI7MjJa2ErPvjR',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Services',
                        code: 'SV',
                        type: 'Category',
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
    contentType: 'frame',
  },
];
